.header {
    position: fixed;
    width: 100%;
    background-color: #eee;
    height: 9rem;
    z-index: 20;
    box-shadow: 0 10px 100px rgba(0, 0, 0, 0.1);
}

.header-content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 5rem;
    box-sizing: border-box;
}

.header-logo {
    display: flex;
    align-items: center;
}

.header-logo__img {
    width: 5rem;
    height: 5rem;
    border-radius: .5rem;
    margin-right: 1.6rem;
}

.header-logo__name {
    text-decoration: none;
    font-size: 1.8rem;
    text-transform: uppercase;
    color: black;
    letter-spacing: 1px;
    font-family: "Source Sans Pro";
    font-weight: bold;
    transition: color .5s;
}

.header-logo__name:hover {
    color: #0062b9;
}

.header-link {
    text-decoration: none;
    font-size: 1.6rem;
    padding: 2.2rem 3rem;
    text-transform: uppercase;
    color: black;
    letter-spacing: 1px;
    font-family: "Source Sans Pro";
    font-weight: bold;
    transition: color .5s;
}

.header-link:hover {
    color: #0062b9;
}

.line {
    height: .2rem;
    width: 2.5rem;
    background-color: #222;
    margin-bottom: .6rem;
}

.line-1,
.line-3 {
    width: 1.8rem;
    transition: all .4s;
}

.menu-icon:hover .line {
    width: 2.5rem;
}

.line1Transition {
    transform: rotate(-405deg) translate(-.5rem, .6rem);
    width: 2.5rem;
}

.line2Transition {
    opacity: 0;
}

.line3Transition {
    transform: rotate(405deg) translate(-.5rem, -.6rem);
    width: 2.5rem;
}

.menu-icon {
    display: none;
}

.ham-menu {
    position: fixed;
    top: -36rem;
    width: 100%;
    margin: 0;
    background: #eee;
    transition: all .5s;
    z-index: 10;
    /* display: none;
    opacity: 0; */
}

.ham-menu-open {
    top: 9rem;
    /* display: block;
    opacity: 1; */
}

.ham-menu-nav-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.ham-menu-nav-item {
    text-align: right;
    font: bold 1.6rem 'Source Sans Pro', sans-serif;
    display: block;
    box-sizing: border-box;
    text-decoration: none;
    text-transform: uppercase;
    color: #333;
    padding: 2.5rem 3rem;
    border-bottom: 1px solid white;
    letter-spacing: 2px;
    transition: color .3s;
}

.ham-menu-nav-item:hover {
    color: #0062b9;
}

.ham-menu-nav-item:first-child {
    border-top: 1px solid white;
}

.ham-menu-nav-item:last-child {
    border-bottom: 0;
}

@media (max-width: 1100px) {
    .header-content {
        padding: 1rem 2rem;
    }

    .header-links {
        display: none;
    }

    .menu-icon {
        display: block;
    }
}