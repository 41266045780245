.footer {
    width: 100%;
    background-color: black;
}

.footer-content {
    max-width: 130rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #eee;
}

.footer-upper {
    width: 92%;
    display: flex;
    justify-content: space-between;
    padding: 6rem 0;
}

.footer-upper-info {
    max-width: 40%;
}

.footer-heading {
    font: bold 2.2rem 'Source Sans Pro', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 0;
}

.footer-upper-info__detail {
    font: 600 1.5rem/1.7 'Source Sans Pro', sans-serif;
    margin-top: 2rem;
    margin-bottom: 0;
}

.footer-upper-social-links {
    display: flex;
}

.footer-upper-social-link_link {
    display: block;
    margin-top: 2rem;
    margin-right: 1.2rem;
    width: 2.5rem;
}

.footer-upper-social-link:last-child a {
    margin-right: 0;
}

.footer-upper-social-link__img {
    width: 100%;
}

.footer-lower {
    width: 92%;
    border-top: 1px solid #444;
}

.footer-lower p {
    font: normal 1.2rem 'Source Sans Pro', sans-serif;
    color: #eee;
    text-align: center;
    margin: 0;
    padding: 2rem;
}

@media (max-width: 600px) {
    .footer-upper {
        padding: 4rem 0;
        flex-direction: column;
    }

    .footer-upper-socials {
        order: 0;
    }

    .footer-upper-info {
        max-width: 100%;
        text-align: justify;
        margin-top: 4rem;
        order: 2;
    }

    .footer-upper-social-link_link {
        width: 3rem;
    }
}